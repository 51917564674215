import React, { useEffect, useRef, useState, createElement, } from "react";
import {
    Page, Splitter, Navigator,
    SplitterContent, SplitterSide, List, ListItem, Carousel, CarouselItem,
} from "react-onsenui";

import Home from "./components/Home";
import Settings from "./components/Settings";

const App = () => {
    const navigator = useRef(null);

    const [isOpen, setIsOpen] = useState(false);
    const [leftSplitterIsOpen, setLeftSplitterIsOpen] = useState(false);
    const [videoId, setVideoId] = useState("");
    
  
    const hide = () => {
        setIsOpen(false);
    };
  
    const show = () => {
        setIsOpen(true);
    };

    const hideLeftSplitter = () => {
        setLeftSplitterIsOpen(false);
    };
  
    const showLeftSplitter = () => {
        setLeftSplitterIsOpen(true);
    };
  
    const loadPage = (page, key) => {
        hide();
        const currentPage = navigator.current.pages.slice(-1)[0];
        // --- or [this.navigator.pages.length - 1]
        if (currentPage.key != page.name) {
            navigator.current.resetPage({
                component: page,
                props: {
                    key: page.name
                },
            }, {
                animation: "fade",
            });
        }
    };
  
    const renderPage = (route, navigator) => {
        route.props = route.props || {};
        route.props.navigator = navigator;
        route.props.showMenu = show;
        route.props.showLeftSplitter = showLeftSplitter;
        route.props.videoId = videoId;
    
        return createElement(route.component, route.props);
    };
    
    return (
        <Splitter>
            <SplitterSide side="left" width={220} collapse={true} isOpen={leftSplitterIsOpen} onClose={hideLeftSplitter} onOpen={showLeftSplitter}>
                <Page>
                    <List>
                        <ListItem style={{cursor: "pointer",}} tappable onClick={() => {
                            setVideoId("0GXb8jF0eOI");
                            hideLeftSplitter();
                        }}>Roughnecks - 1/10/21</ListItem>
                        <ListItem style={{cursor: "pointer",}} tappable onClick={() => {
                            setVideoId("1LWlzFjBukI");
                            hideLeftSplitter();
                        }}>Schmaulers - 12/20/20</ListItem>
                        <ListItem style={{cursor: "pointer",}} tappable onClick={() => {
                            setVideoId("tVL_i1DWr3M");
                            hideLeftSplitter();
                        }}>Maulers - 12/13/20</ListItem>
                        <ListItem style={{cursor: "pointer",}} tappable onClick={() => {
                            setVideoId("uu3QPg4ZB3I");
                            hideLeftSplitter();
                        }}>Roughnecks - 11/22/20</ListItem>
                        <ListItem style={{cursor: "pointer",}} tappable onClick={() => {
                            setVideoId("misvBIaN6nI");
                            hideLeftSplitter();
                        }}>Crabs - 11/15/20</ListItem>
                        {/* <ListItem key={"home-list-item"} onClick={() => loadPage(Home, "home")} tappable>Home</ListItem>
                        <ListItem key={"settings-list-item"} onClick={() => loadPage(Settings, "settings")} tappable>Settings</ListItem> */}
                    </List>
                </Page>
            </SplitterSide>

            <SplitterSide side="right" width={220} collapse={true} isOpen={isOpen} onClose={hide} onOpen={show}>
                <Page>
                    <List>
                        <ListItem key={"home-list-item"} onClick={() => loadPage(Home, "home")} tappable>Home</ListItem>
                        <ListItem key={"settings-list-item"} onClick={() => loadPage(Settings, "settings")} tappable>Settings</ListItem>
                    </List>
                </Page>
            </SplitterSide>
            
            <SplitterContent>
                <Navigator initialRoute={{ component: Home, props: { key: "home" } }} renderPage={renderPage} ref={navigator} />
            </SplitterContent>
        </Splitter>
    );
}

export default App;