// import React from "react";
import {
    BackButton, Icon, Toolbar, ToolbarButton,
} from "react-onsenui";

export default (props) => {
    const {
        showBackButton, showMenu, showLeftSplitter,
    } = props;

    return (
        <Toolbar>
            <div className="left">
                {showBackButton ? 
                    <BackButton>Back</BackButton>
                        :
                    <ToolbarButton onClick={showLeftSplitter} style={{cursor: "pointer",}}>
                        <Icon icon="md-menu" />
                    </ToolbarButton>
                }
            </div>
            <div className="center">Ice Pack</div>
            {/* <div className="right">
                <ToolbarButton onClick={showMenu}>
                    <Icon icon="md-menu" />
                </ToolbarButton>
            </div> */}
        </Toolbar>
    );
};