import React, { useEffect, useState, useRef, } from "react";
import {
    Page, Button,
} from "react-onsenui";

import Admin from "./Admin";
import TopToolbar from "./TopToolbar";

import IcepackLogo from "../icepack_logo.png";

import YouTube from "react-youtube";

export default (props) => {
    const {
        navigator, showMenu, showLeftSplitter, videoId,
    } = props;

    const pageContainer = useRef(null);

    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    const videoOptions = {
        height: height,
        width: width,
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
        },
    };

    useEffect(() => {
        const width = Math.min(pageContainer.current.offsetWidth * 0.90, 500);
        setWidth(width);
        setHeight(width * (9/16));
    }, []);
    
    const renderToolbar = () => {
        const props = {
            showBackButton: false,
            showMenu: showMenu,
            showLeftSplitter: showLeftSplitter,
        };
        return TopToolbar(props);
    };
  
    const pushPage = () => {
        navigator.pushPage({
            component: Admin,
            props: {
                key: "admin",
            },
        });
    };
    
    return (
        <div ref={pageContainer}>
            <Page renderToolbar={renderToolbar}>
                {/* <h2 style={{ textAlign: "center" }}>Home</h2> */}
                {/* <div style={{ textAlign: "center" }}>
                    <br />
                    <Button onClick={pushPage}>
                        Open Admin Page
                    </Button>
                </div> */}

                <p style={{ textAlign: "center", opacity: "0.6", paddingTop: "20px" }}>
                    Click the menu icon in the top left to see the list of games!
                </p>

                {videoId.length ?
                    <div style={{ textAlign: "center", marginTop: "25px" }}>
                        <YouTube
                            videoId={videoId}
                            opts={videoOptions}
                        />
                        <br />
                        <a href={`https://youtu.be/${videoId}`}>View on YouTube</a>
                    </div>
                    :
                    <div>
                        <img src={IcepackLogo} width={width} height={width} style={{display: "block", margin: "0 auto",}} />
                    </div>
                }
            </Page>
        </div>
    );
}