// import React from "react";
import {
    Page,
} from "react-onsenui";

import TopToolbar from "./TopToolbar";

export default (props) => {
    const {
        showMenu, 
    } = props;

    const renderToolbar = () => {
        const props = {
            showBackButton: false,
            showMenu: showMenu,
        };
        return TopToolbar(props);
    };
    
    return (
        <Page renderToolbar={renderToolbar}>
            <h2 style={{textAlign: "center",}}>Settings</h2>
            <p style={{
                textAlign: "center",
                opacity: "0.6",
                paddingTop: "20px"
            }}>
                Swipe left to open the menu!
            </p>
        </Page>
    );
}